import GalleyImage from '../assets/images/3d-galley-kitchen.png';
import DiningImage from '../assets/images/3d-kitchen-dining.png';
import IslandImage from '../assets/images/3d-kitchen-island.png';

const designerContent = {
	rooms: [
		{
			'title': 'Galley Kitchen',
			'slug': 'galley-kitchen',
			'url': 'https://www.articadroom.com/roomviewer-trend/207/styler3/trend1',
			'thumbnail': GalleyImage,
		},
		{
			'title': 'Open-plan Kitchen & Dining Room',
			'slug': 'open-plan-kitchen-diner',
			'url': 'https://www.articadroom.com/roomviewer-trend/207/styler3/trend2',
			'thumbnail': DiningImage,
		},
		{
			'title': 'Spacious Kitchen with Island',
			'slug': 'island-kitchen',
			'url': 'https://www.articadroom.com/roomviewer-trend/207/styler3/trend3',
			'thumbnail': IslandImage,
		}
	]
};

export default designerContent;