import packageJson from '../../package.json';
import * as serviceWorkerRegistration from '../serviceWorkerRegistration';

export function pwaClearCache() {
	caches.keys().then(names => {
		for (let name of names) {
			caches.delete(name);
		}

		serviceWorkerRegistration.unregister();
		window.location.pathname = '';
	})
}

export function pwaGetSetting(setting) {
	return localStorage.getItem(setting);
}

export function pwaUpdateSetting(setting, value) {
	return localStorage.setItem(setting, value);
}

export function pwaGetPackageKey(key) {
	return packageJson[key];
}