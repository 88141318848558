import wpapiConfigContext from "../context/wpapiConfigContext";
import { useContext, useState, useEffect } from "react";

const METHODS_NO_BODY = ['GET', 'HEAD', 'OPTIONS'];
const METHODS_CACHE = ['GET'];

function _createRouteURL(base, method, route, params = {}) {
	if (base.endsWith('/')) {
		base = base.slice(0, -1)
	}

	if (route.startsWith('/')) {
		route = route.slice(1);
	}

	// Todo: Testing with non-pretty permalinks. E.i. ?rest_route=...
	const url = new URL(base + '/' + route);

	if (METHODS_NO_BODY.includes(method)) {
		for (const key in params) {
			url.searchParams.append(key, params[key]);
		}
	}

	return url.href;
}

async function _fetchJSON(method, url, params = {}, opts = {}) {
	method = method.toUpperCase().trim();

	const fetchOpts = {
		...opts,
		method,
	};

	if (!METHODS_NO_BODY.includes(method)) {
		if (params instanceof FormData) {
			fetchOpts.body = params;
		}
		else {
			fetchOpts.headers.append('Content-Type', 'application/json');
			fetchOpts.body = JSON.stringify(params);
		}
	}

	if (METHODS_CACHE.includes(method) && getCachedData(url)) {
		return getCachedData(url);
	}

	const response = await fetch(url, fetchOpts);
	const json = await response.json();

	const data = {
		response,
		json,
	};

	if (METHODS_CACHE.includes(method)) {
		cacheData(url, data);
	}

	return data;
}

export function useQuery(method, route, params = {}, opts = {}) {
	const config = useContext(wpapiConfigContext);

	const [headers, setHeaders] = useState();
	const [body, setBody] = useState();
	const [isOk, setIsOk] = useState();
	const [status, setStatus] = useState();
	const [error, setError] = useState();
	const [isLoading, setIsLoading] = useState(true);

	let url = _createRouteURL(config.baseUrl, method, route, params);

	if (config.nonce) {
		opts.headers = opts.headers || {};
		opts.headers['X-WP-Nonce'] = config.nonce;
	}

	useEffect(() => {
		setIsLoading(true);

		_fetchJSON(method, url, params, opts)
			.then(({ json, response }) => {
				setHeaders(response.headers);
				setBody(json);
				setIsOk(response.ok);
				setStatus(response.status);
				setError(false);
			})
			.catch(error => {
				setHeaders(null);
				setBody(null);
				setIsOk(false);
				setStatus(500);
				setError(error);
			})
			.finally(() => {
				setIsLoading(false);
			});
	}, [method, route]);

	return {
		body,
		headers,
		isOk,
		status,
		isLoading,
		error,
	};
}

export function useGet(route, params = {}, opts = {}) {
	return useQuery('GET', route, params, opts);
}

export function useHead(route, params = {}, opts = {}) {
	return useQuery('HEAD', route, params, opts);
}

export function useOptions(route, params = {}, opts = {}) {
	return useQuery('OPTIONS', route, params, opts);
}

export function usePost(route, params = {}, opts = {}) {
	return useQuery('POST', route, params, opts);
}

export function usePut(route, params = {}, opts = {}) {
	return useQuery('PUT', route, params, opts);
}

export function usePatch(route, params = {}, opts = {}) {
	return useQuery('PATCH', route, params, opts);
}

export function useDelete(route, params = {}, opts = {}) {
	return useQuery('DELETE', route, params, opts);
}

const cache = {};

export function cacheData(key, data) {
	cache[key] = data;
}

export function getCachedData(key) {
	return cache[key];
}