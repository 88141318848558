import React from "react";
import TransitionLink from "./TransitionLink";

export default function Card({
	to,
	image,
	icon,
	hidden = false,
	children
}) {
	if (hidden) {
		return null;
	}

	return (
		<TransitionLink to={to}>
			{image &&
				<img className="w-full aspect-square" src={image} />
			}

			<h3 className="mt-6 text-2xl text-orange text-left flex items-center">
				{icon &&
					<img className="w-10 h-10 mr-4" src={icon} />
				}

				{children}
			</h3>
		</TransitionLink>
	);
}