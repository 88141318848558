import React, { useEffect, useState } from "react";

export default function TimedPopup({ wait = 5000, children, className }) {
	const [isHidden, setIsHidden] = useState(true);

	useEffect(() => {
		setTimeout(() => {
			setIsHidden(false);
		}, wait);
	}, [wait]);

	const hiddenClasses =
		"transition duration-500 opacity-0 translate-y-10 pointer-events-none";
	const shownClasses = "transition duration-500 opacity-100";

	const handleClick = () => setIsHidden(true);

	return (
		<button
			className={className + " " + (isHidden ? hiddenClasses : shownClasses)}
			onClick={handleClick}
		>
			{children}
		</button>
	);
}
