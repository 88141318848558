import React from "react";
import TransitionLink from "./TransitionLink";
import { useLocation } from "react-router-dom";

import trustpilotLogoImage from "../assets/images/trustpilot-logo.svg";
import trustpilotRatingImage from "../assets/images/trustpilot-rating.svg";
import logoImage from "../assets/images/logo-icon.png";
import { isConnectionStrong } from "../helpers/connection";

export default function NavBar() {
	const location = useLocation();
	const isProductSingle =
		location.pathname.includes("/product/worktop/") ||
		location.pathname.includes("/product/replacement_door/");

	return (
		<nav className="view-transition-nav relative z-40 bg-white">
			<TransitionLink
				to="/reviews"
				className="flex items-center justify-center w-full bg-[#000032] font-[Arial] text-white text-center text-sm py-2"
			>
				Rated Excellent on{" "}
				<img
					className="h-5 ml-2 -translate-y-0.5"
					alt="TrustPilot"
					src={trustpilotLogoImage}
				/>
				<img className="h-4 ml-6" alt="4.8 Stars" src={trustpilotRatingImage} />
			</TransitionLink>

			<div className="px-28 py-8 flex items-center gap-8 text-orange text-2xl">
				{location.pathname !== "/" && !isProductSingle && (
					<TransitionLink to="/" className="flex items-center mr-auto">
						<svg
							className="mr-8"
							xmlns="http://www.w3.org/2000/svg"
							width="48.207"
							height="15.414"
							viewBox="0 0 48.207 15.414"
						>
							<g
								id="Group_2271"
								data-name="Group 2271"
								transform="translate(47.707 0.707)"
							>
								<line
									id="Line_134"
									data-name="Line 134"
									x1="47"
									transform="translate(-47 7)"
									fill="none"
									stroke="#df5c24"
									strokeLinecap="square"
									strokeWidth="1"
								/>
								<line
									id="Line_135"
									data-name="Line 135"
									x1="7"
									y2="7"
									transform="translate(-47)"
									fill="none"
									stroke="#df5c24"
									strokeLinecap="square"
									strokeWidth="1"
								/>
								<line
									id="Line_136"
									data-name="Line 136"
									x1="7"
									y1="7"
									transform="translate(-47 7)"
									fill="none"
									stroke="#df5c24"
									strokeLinecap="square"
									strokeWidth="1"
								/>
							</g>
						</svg>
						Home
					</TransitionLink>
				)}

				{location.pathname !== "/" && isProductSingle && (
					<TransitionLink back className="flex items-center mr-auto capitalize">
						<svg
							className="mr-8"
							xmlns="http://www.w3.org/2000/svg"
							width="48.207"
							height="15.414"
							viewBox="0 0 48.207 15.414"
						>
							<g
								id="Group_2271"
								data-name="Group 2271"
								transform="translate(47.707 0.707)"
							>
								<line
									id="Line_134"
									data-name="Line 134"
									x1="47"
									transform="translate(-47 7)"
									fill="none"
									stroke="#df5c24"
									strokeLinecap="square"
									strokeWidth="1"
								/>
								<line
									id="Line_135"
									data-name="Line 135"
									x1="7"
									y2="7"
									transform="translate(-47)"
									fill="none"
									stroke="#df5c24"
									strokeLinecap="square"
									strokeWidth="1"
								/>
								<line
									id="Line_136"
									data-name="Line 136"
									x1="7"
									y1="7"
									transform="translate(-47 7)"
									fill="none"
									stroke="#df5c24"
									strokeLinecap="square"
									strokeWidth="1"
								/>
							</g>
						</svg>
						All Products
					</TransitionLink>
				)}

				{location.pathname !== "/about" && (
					<TransitionLink to="/about" className="ml-auto">
						{isConnectionStrong()
							? "Speak to a Design Consultant"
							: "Visit our Website"}
					</TransitionLink>
				)}

				<TransitionLink to="/">
					<img
						className="h-10 w-auto ml-16"
						src={logoImage}
						width="81"
						height="51"
					/>
				</TransitionLink>
			</div>
		</nav>
	);
}
