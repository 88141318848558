import React, { useEffect, useState } from "react";

export default function ScrollIndiciator({
	threshold = 150,
	className,
	children,
}) {
	const [isHidden, setIsHidden] = useState(0);

	useEffect(() => {
		const scrollHandler = () => {
			setIsHidden(window.scrollY > threshold);
		}

		window.addEventListener('scroll', scrollHandler);

		return () => {
			window.removeEventListener('scroll', scrollHandler);
		}
	})

	return (
		<div className={className + ' transition-opacity ' + (isHidden ? 'opacity-0' : 'opacity-100')}>
			{children}
		</div>
	)
}