import Carousel from "nuka-carousel";
import React from "react";

function chunk(arr, size) {
	const chunks = [];

	for (let i = 0; i < arr.length; i += size) {
		const chunk = arr.slice(i, i + size);
		chunks.push(chunk);
	}

	return chunks;
}

export default function ProductColours({ colours }) {
	const slides = chunk(colours, 10);

	return (
		<div className="relative max-w-4xl mx-auto">
			<Carousel
				wrapAround={true}
				dragThreshold={0.1}
				renderCenterLeftControls={false}
				renderCenterRightControls={false}
				renderBottomCenterControls={false}
				renderBottomRightControls={({ currentSlide, slideCount }) => (
					<ul className="flex gap-2 translate-y-6">
						{[...Array(slideCount)].map((_, i) => (
							<li
								key={i}
								className={
									"w-2 h-2 rounded-full " +
									(currentSlide === i ? "bg-black" : "bg-black/50")
								}
							/>
						))}
					</ul>
				)}
			>
				{slides.map((cols, i) => (
					<div className="grid grid-cols-5 gap-4" key={i}>
						{cols.map((color, i) => (
							<div
								key={i}
								className="bg-cover bg-no-repeat bg-center overflow-hidden w-full h-0 rounded-md shadow-md"
								style={{
									paddingTop: (7.5 / 4) * 100 + "%",
									backgroundImage: `url(${color.swatch?.medium})`,
								}}
							/>
						))}
					</div>
				))}
			</Carousel>
		</div>
	);
}
