import React from "react";
import useScreensaver from "../hooks/useScreensaver";

import kitchenIcon from "../assets/icons/kitchen.svg";
import compareIcon from "../assets/icons/compare.svg";
import cabinetsIcon from "../assets/icons/cabinets.svg";
import infomercialVideo from "../assets/videos/infomercial.mp4";
import logoImage from "../assets/images/logo-icon.png";
import trustPilot from "../assets/images/trustpilot-badge.svg";

export default function ScreenSaver() {
	const isOpen = useScreensaver("/", 180);

	return (
		<div
			id="screensaver"
			className={
				"fixed top-0 left-0 right-0 bottom-0 h-full z-50 duration-700 transition-opacity " +
				(isOpen ? "opacity-100" : "opacity-0 pointer-events-none")
			}
		>
			<div className="grid grid-cols-2 h-full">
				<video
					className="w-full h-full max-h-screen object-cover bg-white"
					autoPlay
					loop
					playsInline
					muted
				>
					<source src={infomercialVideo} crossOrigin="anonymous"></source>
				</video>

				<div className="flex flex-col justify-between bg-white bg-faded-kitchen bg-cover bg-center shadow-xl text-center">
					<div className="flex flex-col items-center justify-around grow pb-32 bg-gradient-to-t from-white">
						<div className="flex items-center gap-16">
							<img
								className="w-auto h-16"
								src={logoImage}
								width="500"
								height="72"
							/>
							<img className="wauto h-16" src={trustPilot} />
						</div>

						<div className="p-8 self-center">
							<h2 className="text-7xl mb-8">Tap to Start</h2>

							<p className="text-2xl max-w-2xl">
								Everything you need to create your kitchen makeover is on the
								screen.
							</p>
						</div>
					</div>

					<div className="bg-beige w-full px-16">
						<ul className="-translate-y-12 grid grid-cols-3 gap-12">
							<li className="flex flex-col items-center text-center">
								<img className="w-24 h-24 mb-6" src={kitchenIcon} />

								<p>Touch me anywhere to design your dream kitchen makeover</p>
							</li>

							<li className="flex flex-col items-center text-center">
								<img className="w-24 h-24 mb-6" src={compareIcon} />

								<p>Take a look at some of our recent before & after pictures</p>
							</li>

							<li className="flex flex-col items-center text-center">
								<img className="w-24 h-24 mb-6" src={cabinetsIcon} />

								<p>
									See our collection of Worktops & made-to-measure Kitchen Doors
								</p>
							</li>
						</ul>
					</div>
				</div>
			</div>
		</div>
	);
}
