import React from "react";
import { isConnectionStrong } from "../helpers/connection";
import TransitionLink from "../components/TransitionLink";
import CardsContainer from "../components/CardsContainer";
import Card from "../components/Card";

import kitchenIcon from '../assets/icons/kitchen.svg'
import compareIcon from '../assets/icons/compare.svg'
import cabinetsIcon from '../assets/icons/cabinets.svg'

import designToolImage from '../assets/images/designtool.png'
import beforeAndAfterImage from '../assets/images/beforeandafter.png'
import kitchenImage from '../assets/images/kitchen.png'
import TrustpilotWidget from "../components/TrustpilotWidget";


export default function Home() {
	return (
		<div className="py-16 px-28">
			<div className="flex items-start justify-between">
				<div>
					<h2 className="text-6xl mb-6">
						How would you like to begin?
					</h2>

					<p className="text-lg max-w-2xl leading-loose mb-14">
						Start designing your dream kitchen, take a look at our previous transformations or browse our full catalogue of overlay worktops & replacement doors.
					</p>
				</div>

				<TrustpilotWidget templateID="54d0e1d8764ea9078c79e6ee" height="140px" className="pointer-events-none" />
			</div>

			<CardsContainer>
				<Card to="/designers" image={designToolImage} icon={kitchenIcon} hidden={!isConnectionStrong()}>
					Design your Dream Kitchen
				</Card>

				<Card to="/gallery" image={beforeAndAfterImage} icon={compareIcon}>
					View our Before & After Gallery
				</Card>

				<Card to="/products" image={kitchenImage} icon={cabinetsIcon}>
					View our Worktops & Kitchen Doors
				</Card>
			</CardsContainer>

			<TransitionLink className="mt-36 text-gray-400" to="/settings">Settings</TransitionLink>
		</div >
	)
}