import React from "react";
import { getPostFeaturedImage } from "../helpers/wpapi";
import TransitionLink from "./TransitionLink";
import { useGetProduct } from "../hooks/useProducts";
import Skeleton from "./Skeleton";

export default function ProductPreview({ className = "", type, id }) {
	const { product, isLoading } = useGetProduct(type, id);

	const thumbnail = getPostFeaturedImage(product, ["large", "medium", "full"]);
	const title = product?.title?.rendered;

	return (
		<TransitionLink
			className={"block relative " + className}
			to={`/product/${type}/${id}`}
			scroll="0"
		>
			<Skeleton
				as="div"
				isLoading={isLoading}
				className="bg-cover bg-no-repeat bg-center overflow-hidden w-full h-0 rounded-md shadow-md"
				style={{
					paddingTop:
						(type === "replacement_door" ? (7.5 / 4) * 100 : (4 / 7) * 100) +
						"%",
					backgroundImage: `url(${encodeURI(thumbnail)})`,
				}}
			/>

			{product?.badge && (
				<span className="text-white text-xs bg-black absolute top-6 left-0 py-1 px-2 rounded-r">
					{product.badge}
				</span>
			)}

			<Skeleton
				as="h3"
				className="text-xl mt-3"
				isLoading={isLoading}
				placeholder="Loading..."
			>
				{title}
			</Skeleton>
		</TransitionLink>
	);
}
