import TrustpilotWidget from "../components/TrustpilotWidget";

export default function Reviews() {
	return (
		<div className="relative py-16 px-28">
			<TrustpilotWidget templateID="539adbd6dec7e10e686debee" height="2500px" />

			<div className="bg-orange absolute w-full h-44 opacity-0 left-0 right-0 top-0" />
		</div>
	);
}