import { useLocation } from "react-router-dom";
import ReactGA from "react-ga4";
import { useEffect } from "react";
import useFranchise from "../hooks/useFranchise";

export default function GA4RouterLayer({
	children,
	measurementID,
}) {
	const location = useLocation();
	const franchise = useFranchise();

	useEffect(() => {
		ReactGA.initialize(measurementID);

		ReactGA.gtag('set', 'user_properties', {
			franchise: franchise?.name
		});
	}, []);

	useEffect(() => {
		ReactGA.send({
			hitType: "pageview",
			page: location.pathname,
			title: location.pathname,
		});
	}, [location]);

	// Set custom user dimension/session dimension for the showroom name?
	// Indicate end of session when the screensaver shows?

	return children;
}