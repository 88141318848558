import React, { useState } from "react";
import {
	pwaClearCache,
	pwaGetPackageKey,
	pwaGetSetting,
	pwaUpdateSetting,
} from "../helpers/pwa-controls";
import { useGet } from "../hooks/useWPAPI";

export default function Settings() {
	const {
		isOk: isFranchisesOk,
		isLoading: isFranchisesLoading,
		body: franchises,
	} = useGet("/gtt/v1/franchises");
	const hasFranchises =
		isFranchisesOk && !isFranchisesLoading && franchises && franchises.length;

	const [selectedFranchise, setSelectedFranchise] = useState(
		pwaGetSetting("franchise")
	);
	const handleSelectedFranchiseChange = (evt) => {
		pwaUpdateSetting("franchise", evt.target.value);
		setSelectedFranchise(evt.target.value);
	};

	const [isManned, setIsManned] = useState(
		Boolean(parseInt(pwaGetSetting("isManned")))
	);
	const handleIsMannedChange = (evt) => {
		pwaUpdateSetting("isManned", evt.target.checked ? 1 : 0);
		setIsManned(evt.target.checked ? 1 : 0);
	};

	const handheldFranchise = {
		name: "Handheld/In-person",
		phone: "",
		url: "",
	};

	return (
		<div className="bg-beige grow py-16 px-28">
			<h1 className="text-4xl">Settings</h1>

			<p className="text-sm mt-2">
				{pwaGetPackageKey("name") + " v" + pwaGetPackageKey("version")}
			</p>

			<div className="grid grid-cols-2 gap-10 mt-10">
				<div>
					<label className="block mb-10">
						<h2 className="mb-2">Current Franchise</h2>

						<select
							placeholder="Loading.."
							value={selectedFranchise}
							onChange={handleSelectedFranchiseChange}
							className="py-2 px-4 shadow rounded w-full"
						>
							{hasFranchises ? (
								<>
									<option value={JSON.stringify(handheldFranchise)}>
										{handheldFranchise.name}
									</option>
									<option disabled>------</option>

									{franchises.map((franchise, i) => (
										<option value={JSON.stringify(franchise)} key={i}>
											{franchise.name}
										</option>
									))}
								</>
							) : (
								<option disabled>Loading...</option>
							)}
						</select>
					</label>

					<label>
						Is showroom manned?
						<input
							className="block mt-2 border-4 border-white w-5 h-5 rounded appearance-none bg-white shadow checked:bg-orange"
							type="checkbox"
							checked={isManned}
							onChange={handleIsMannedChange}
						/>
					</label>
				</div>

				<div>
					<h2 className="mb-2">Advanced</h2>

					<button
						onClick={pwaClearCache}
						className="bg-orange p-2 rounded text-white font-medium text-center block w-full"
					>
						Clear App Cache & Reload
					</button>
				</div>
			</div>
		</div>
	);
}
