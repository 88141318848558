import React from "react";
import QRCode from "react-qr-code";
import formContent from "../content/formContent";
import { isConnectionStrong } from "../helpers/connection";
import useFranchise from "../hooks/useFranchise";
import TrustpilotWidget from "../components/TrustpilotWidget";
import TransitionLink from "../components/TransitionLink";

export default function About() {
	const franchise = useFranchise();

	return (
		<div className="grid grid-cols-5 items-start gap-24 bg-beige grow py-20 px-28">
			{franchise &&
				<div className="col-span-2 flex flex-col items-center justify-center text-center">
					<div className="bg-white rounded-full p-16 mb-10">
						<QRCode value={franchise?.url} size={164} />
					</div>

					<h2 className="text-5xl mb-6">
						{franchise?.name}
					</h2>

					<p className="text-2xl mb-6">
						{franchise?.phone}
					</p>

					<p className="text-2xl">
						{franchise?.url.replace('https://', '')}
					</p>

					<TransitionLink to="/reviews" className="block mt-8 translate-x-3" scroll="0">
						<TrustpilotWidget templateID="53aa8807dec7e10d38f59f32" className="pointer-events-none" />
					</TransitionLink>
				</div>
			}

			{isConnectionStrong() &&
				<div className="col-span-3">
					<h2 className="text-5xl mb-6">
						Speak to a Design Consultant
					</h2>

					<p>
						A member of our team will get in touch about scheduling your free, in-home consultation or give you a callback.
					</p>

					<div
						className=""
						dangerouslySetInnerHTML={{ __html: formContent.code }}
					/>
				</div>
			}
		</div>
	)
}