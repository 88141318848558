import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

export default function useScreensaver(navigateTo, inactivityDurationInSeconds = 60000) {
	const navigate = useNavigate();
	const [isOpen, setIsOpen] = useState(true);

	useEffect(() => {
		const activityEvents = ['mousedown', 'keydown', 'touchstart', 'wheel', 'touchmove'];

		let activityTimer = null;

		const onActivity = (evt) => {
			setIsOpen(false)

			clearTimeout(activityTimer)

			activityTimer = setTimeout(() => {
				setIsOpen(true)
				setTimeout(() => {
					navigate(navigateTo)
				}, 500)
			}, inactivityDurationInSeconds * 1000)
		}

		activityEvents.forEach(evt => {
			window.addEventListener(evt, onActivity);
		})

		return () => {
			clearTimeout(activityTimer)

			activityEvents.forEach(evt => {
				window.removeEventListener(evt, onActivity);
			})
		}
	}, [])

	return isOpen;
}