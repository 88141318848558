import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { getPostFeaturedImageSize } from "../helpers/wpapi";
import productContent from "./../content/productContent";
import Carousel from "nuka-carousel";
import { useGetProduct } from "../hooks/useProducts";
import Skeleton from "../components/Skeleton";
import { loremIpsum } from "lorem-ipsum";
import ProductFeature from "../components/ProductFeature";
import TimedPopup from "../components/TimedPopup";
import TransitionLink from "../components/TransitionLink";
import ProductColours from "../components/ProductColours";
import closeIcon from "../assets/icons/close.svg";
import SwipeIcon from "../assets/icons/swipe.svg";
import ProductPreview from "../components/ProductPreview";
import TrustpilotWidget from "../components/TrustpilotWidget";

export default function Product() {
	const [isSwipeIndicatorHidden, setIsSwipeIndicatorHidden] = useState(false);

	const params = useParams();

	const { product, isLoading } = useGetProduct(params.type, params.id);

	const thumbnail = getPostFeaturedImageSize(product, [
		"large",
		"medium",
		"full",
	]);

	const hasCarousel = product?.gallery?.length > 0;
	const hasMultipleSlides = product?.gallery?.length > 1;

	return (
		<div>
			{hasCarousel && (
				<div
					className="relative"
					onTouchStart={() => setIsSwipeIndicatorHidden(true)}
				>
					<Carousel
						autoplay={hasMultipleSlides}
						autoplayInterval={5000}
						wrapAround={true}
						dragThreshold={0.1}
						renderCenterLeftControls={false}
						renderCenterRightControls={false}
						renderBottomCenterControls={false}
						renderBottomRightControls={({
							currentSlide,
							slideCount,
							goToSlide,
						}) => (
							<ul className="flex p-8">
								{[...Array(slideCount)].map((_, i) => (
									<li key={i}>
										<button className="p-3">
											<div
												className={
													"w-3 h-3 rounded-full " +
													(currentSlide === i ? "bg-white" : "bg-white/50")
												}
												onClick={() => goToSlide(i)}
											/>
										</button>
									</li>
								))}
							</ul>
						)}
					>
						{product.gallery.map((galleryItem, i) => {
							const galleryImage = getPostFeaturedImageSize(
								galleryItem,
								["full", "large", "medium"],
								"after_image"
							);
							return (
								<div
									key={i}
									className="w-full h-[30rem] bg-cover bg-center bg-gray-300"
									style={{
										backgroundImage: `url(${encodeURI(galleryImage.url)})`,
									}}
								/>
							);
						})}
					</Carousel>

					<div
						className={
							"flex items-center absolute top-10 right-10 z-20 font-medium bg-white p-3 rounded shadow-lg transition-opacity " +
							((isSwipeIndicatorHidden || !hasMultipleSlides) && "opacity-0")
						}
					>
						<img className="w-5 h-5 mr-4 rotate-90" src={SwipeIcon} />
						Swipe to see more!
					</div>
				</div>
			)}

			<div className="grid grid-cols-[auto_1fr] gap-12 py-16 px-28 w-fit mx-auto">
				<div
					className={`w-[13rem] h-96 bg-gray-200 bg-cover bg-no-repeat bg-center overflow-hidden rounded-md shadow-xl relative z-10 ${
						hasCarousel && "-mt-36"
					}`}
					style={{
						backgroundImage: `url(${encodeURI(thumbnail?.url)})`,
					}}
				/>

				<div>
					<Skeleton
						as="h1"
						className="text-5xl font-medium mb-4"
						isLoading={isLoading}
						placeholder="Calcatta"
					>
						{product?.title?.rendered}
					</Skeleton>

					<Skeleton
						className="block prose text-lg leading-loose max-w-4xl"
						isLoading={isLoading}
						placeholder={loremIpsum({ count: 7 })}
					>
						<div
							dangerouslySetInnerHTML={{ __html: product?.content?.rendered }}
						/>
					</Skeleton>

					<TransitionLink
						to="/reviews"
						className="block w-full mt-8 translate-x-3"
						scroll="0"
					>
						<TrustpilotWidget
							templateID="5419b6ffb0d04a076446a9af"
							height="20px"
							className="pointer-events-none"
						/>
					</TransitionLink>
				</div>
			</div>

			{product?.colours && (
				<div className="px-40 pb-32 pt-6">
					<h2 className="text-4xl text-center mb-12">Colour Range</h2>

					{<ProductColours colours={product.colours} />}
				</div>
			)}

			{product?.related && product?.related?.products?.length > 0 && (
				<div className="px-40 pb-6">
					<h2 className="text-4xl text-center mb-12">
						{product?.related?.title}
					</h2>

					<div className="grid grid-cols-5 gap-4 max-w-4xl mx-auto">
						{product.related.products.map((id) => (
							<ProductPreview key={id} id={id} type={params.type} />
						))}
					</div>
				</div>
			)}

			{product?.features && product?.features?.length > 0 && (
				<div className="flex flex-wrap justify-center py-16 px-28">
					{product.features.map((feature, i) => (
						<ProductFeature
							key={i}
							feature={feature}
							className={"m-10 w-1/4"}
						/>
					))}
				</div>
			)}

			<TimedPopup className="fixed bottom-12 right-12 z-10 bg-white shadow-lg rounded max-w-md">
				<header className="flex items-center gap-6 bg-beige px-10 py-6">
					<h3 className="text-2xl font-medium mr-auto">Want a sample?</h3>

					<img className="w-6" src={closeIcon} />
				</header>

				<p className="px-10 py-8 text-xl">
					Speak to one of our design consultants in the showroom or{" "}
					<TransitionLink to="/about" className="text-orange">
						request a callback
					</TransitionLink>
					.
				</p>
			</TimedPopup>
		</div>
	);
}
