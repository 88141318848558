import BlackKitchenBefore from "../assets/images/gallery/Black Kitchen - Before.jpg";
import BlackKitchenAfter from "../assets/images/gallery/Black Kitchen - After.jpg";
import AllWhiteBefore from "../assets/images/gallery/All White - Before.jpg";
import AllWhiteAfter from "../assets/images/gallery/All White - After.jpg";
import CambridgeBefore from "../assets/images/gallery/Cambridge - Before.jpg";
import CambridgeAfter from "../assets/images/gallery/Cambridge - After.jpg";
import DerbyBefore from "../assets/images/gallery/Derby - Before.jpg";
import DerbyAfter from "../assets/images/gallery/Derby - After.jpg";
import HayleyBefore from "../assets/images/gallery/Hayley - Before.jpg";
import HayleyAfter from "../assets/images/gallery/Hayley - After.jpg";
import IpswichBefore from "../assets/images/gallery/Ipswich - Before.jpg";
import IpswichAfter from "../assets/images/gallery/Ipswich - After.jpg";
import NYNKBefore from "../assets/images/gallery/NYNK - Before.jpg";
import NYNKAfter from "../assets/images/gallery/NYNK - After.jpg";
import PerlaBiancaBefore from "../assets/images/gallery/Perla Bianca - Before.jpg";
import PerlaBiancaAfter from "../assets/images/gallery/Perla Bianca - After.jpg";
import StatuarioBefore from "../assets/images/gallery/Statuario - Before.jpg";
import StatuarioAfter from "../assets/images/gallery/Statuario - After.jpg";

import BuxtonBefore from "../assets/images/gallery/Buxton - Before.jpg";
import BuxtonAfter from "../assets/images/gallery/Buxton - After.jpg";

import HydraWhiteBefore from "../assets/images/gallery/Hydra White - Before.jpg";
import HydraWhiteAfter from "../assets/images/gallery/Hydra White - After.jpg";

import PebbleBefore from "../assets/images/gallery/Pebble - Before.JPG";
import PebbleAfter from "../assets/images/gallery/Pebble - After.JPG";

const galleryContent = {
	images: [
		{
			title: "Bella Buxton Kitchen",
			mode: "tap",
			before: BuxtonBefore,
			after: BuxtonAfter,
		},
		{
			title: "Black Kitchen",
			mode: "slider",
			before: BlackKitchenBefore,
			after: BlackKitchenAfter,
		},
		{
			title: "Hydra White Kitchen",
			mode: "tap",
			before: HydraWhiteBefore,
			after: HydraWhiteAfter,
		},
		{
			title: "Pebble",
			mode: "tap",
			before: PebbleBefore,
			after: PebbleAfter,
		},
		{
			title: "All White Kitchen",
			mode: "slider",
			before: AllWhiteBefore,
			after: AllWhiteAfter,
		},
		{
			title: "Cambridge",
			mode: "tap",
			before: CambridgeBefore,
			after: CambridgeAfter,
		},
		{
			title: "Derby",
			mode: "tap",
			before: DerbyBefore,
			after: DerbyAfter,
		},
		{
			title: "Hayley",
			mode: "tap",
			before: HayleyBefore,
			after: HayleyAfter,
		},
		{
			title: "Ipswich",
			mode: "tap",
			before: IpswichBefore,
			after: IpswichAfter,
		},
		{
			title: "NYNK",
			mode: "tap",
			before: NYNKBefore,
			after: NYNKAfter,
		},
		{
			title: "Perla Bianca",
			mode: "slider",
			before: PerlaBiancaBefore,
			after: PerlaBiancaAfter,
		},
		{
			title: "Statuario",
			mode: "tap",
			before: StatuarioBefore,
			after: StatuarioAfter,
		},
	],
};

export default galleryContent;
