import { useEffect, useRef } from "react";
import useFranchise from "../hooks/useFranchise";

export default function TrustpilotWidget({
	templateID = '53aa8807dec7e10d38f59f32',
	className = '',
	height = '150px',
	width = '100%',
	stars = '1,2,3,4,5',
	theme = 'light'
}) {
	const franchise = useFranchise();

	const ref = useRef(null);

	useEffect(() => {
		if (window.Trustpilot) {
			window.Trustpilot.loadFromElement(ref.current, true);
		}
	}, []);

	return (
		<div
			ref={ref}
			className={"trustpilot-widget " + className}
			data-locale="en-US"
			data-template-id={templateID}
			data-businessunit-id="5ccada1a442b4b00014a5ca4"
			data-style-height={height}
			data-style-width={width}
			data-theme={theme}
			data-location={franchise?.trustpilot_location_id}
			data-schema-type="LocalBusiness"
			data-allow-robots="true"
			data-stars={stars}
		>
			<a href="https://www.trustpilot.com/review/example.com" target="_blank" rel="noopener"> Trustpilot
			</a>
		</div>
	);
}