import React from "react";

export default function ProductFeature({ feature, className }) {
	const useIcon = !feature?.image && feature?.icon;
	const useDescription = feature?.description && feature?.prominent;

	return (
		<div
			className={className + " " + (useDescription ? "w-full xl:w-2/3" : "")}
		>
			<div
				className={
					"flex items-center " +
					(feature?.prominent ? "flex-row gap-14" : "flex-col")
				}
			>
				{feature?.image && (
					<img
						src={feature?.image}
						className="w-full aspect-square object-cover object-center rounded-full bg-beige max-w-xs mb-10"
					/>
				)}

				<div className={"mx-auto " + (useIcon ? "flex items-center" : "")}>
					{useIcon && (
						<img
							src={feature.icon}
							className="max-w-[3rem] max-h-[3rem] mr-8"
						/>
					)}

					<h3 className="text-2xl font-medium whitespace-nowrap">
						{feature?.label}
					</h3>

					{useDescription && (
						<p className="leading-loose text-lg mt-4">
							{feature?.description?.substring(
								0,
								feature?.description.length - 21
							)}
						</p>
					)}
				</div>
			</div>
		</div>
	);
}
