export function getPostFeaturedImage(post, sizes = 'medium', key = 'thumbnails') {
	const size = getPostFeaturedImageSize(post, sizes, key);

	if (!size || !size.url) {
		return undefined;
	}

	return size.url;
}

export function getPostFeaturedImageSize(post, sizes = 'medium', key = 'thumbnails') {
	if (!Array.isArray(sizes)) {
		sizes = [sizes];
	}

	if (!post || !post[key]) {
		return undefined;
	}

	for (const size of sizes) {
		if (post[key][size]) {
			return post[key][size];
		}
	}

	return undefined;
}