import React, { useContext } from 'react';
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import wpapiConfigContext from './context/wpapiConfigContext';
import ScreenSaver from './routes/ScreenSaver';
import Gallery from './routes/Gallery';
import Home from './routes/Home';
import NavBar from './components/NavBar';
import Products from './routes/Products';
import Product from './routes/Product';
import Designers from './routes/Designers';
import Designer from './routes/Designer';
import About from './routes/About';
import Settings from './routes/Settings';
import GA4RouterLayer from './components/GA4RouterLayer';
import Reviews from './routes/Reviews';


function App() {
	const wpapiDefaultConfig = useContext(wpapiConfigContext);
	const wpapiConfig = { ...wpapiDefaultConfig, baseUrl: 'https://granitetransformations.co.uk/wp-json/' };

	return (
		<Router>
			<GA4RouterLayer measurementID='G-EK9MRQ7TWL'>
				<wpapiConfigContext.Provider value={wpapiConfig}>
					<ScreenSaver />


					<main id="main" className='flex flex-col min-h-screen bg-faded-kitchen bg-cover bg-center bg-fixed'>
						<NavBar />

						<Routes>
							<Route path="/" element={<Home />} />
							<Route path="/designers" element={<Designers />} />
							<Route path="/designer/:room" element={<Designer />} />
							<Route path="/gallery" element={<Gallery />} />
							<Route path="/products" element={<Products />} />
							<Route path="/product/:type/:id" element={<Product />} />
							<Route path="/about" element={<About />} />
							<Route path="/settings" element={<Settings />} />
							<Route path="/reviews" element={<Reviews />} />
						</Routes>
					</main>
				</wpapiConfigContext.Provider>
			</GA4RouterLayer>
		</Router >
	);
}

export default App;
