import React, { useState } from "react";
import ProductPreview from "../components/ProductPreview";
import { Tab } from "@headlessui/react";
import { useGetDoors, useGetWorktops } from "../hooks/useProducts";
import ScrollIndiciator from "../components/ScrollIndicator";
import SwipeIcon from "../assets/icons/swipe.svg";

export default function Products() {
	const [selectedIndex, setSelectedIndex] = useState(
		window.location.hash.substring(1) || 0
	);

	const tabs = [
		{
			title: "Worktops",
			response: useGetWorktops(),
		},
		{
			title: "Replacement Doors",
			response: useGetDoors(),
		},
	];

	tabs.forEach((tab, i, arr) => {
		arr[i].hasProducts =
			tab.response.isOk &&
			!tab.response.isLoading &&
			tab.response.body &&
			tab.response.body.length;
		arr[i].products = tab.response.body;
		arr[i].type = !arr[i].products ? "unknown" : arr[i].products[0].type;
	});

	const handleChange = (newIndex) => {
		window.location.hash = newIndex;
		setSelectedIndex(newIndex);
	};

	return (
		<div className="py-16 px-28">
			<Tab.Group selectedIndex={selectedIndex} onChange={handleChange}>
				<Tab.List className="flex">
					{tabs.map((tab, i) => (
						<Tab
							className="text-3xl pb-2 mr-10 border-b-4 border-transparent transition-colors focus:outline-none ui-selected:border-orange"
							key={i}
						>
							{tab.title}
						</Tab>
					))}
				</Tab.List>

				<Tab.Panels className="pt-10">
					{tabs.map((tab, i) => (
						<Tab.Panel key={i}>
							<ScrollIndiciator className="flex items-center fixed bottom-10 right-10 z-20 text-lg font-medium bg-white p-5 rounded shadow-lg">
								<img className="w-6 h-6 mr-4 animate-bounce" src={SwipeIcon} />
								Swipe up to see more!
							</ScrollIndiciator>

							<div
								className="grid gap-6 items-start"
								style={{
									gridTemplateColumns: `repeat(${
										tab.type === "replacement_door" ? 6 : 4
									}, minmax(0, 1fr))`,
								}}
							>
								{!tab.hasProducts ? (
									<div>Loading...</div>
								) : (
									tab.products.map((product) => (
										<ProductPreview
											key={product.id}
											id={product.id}
											type={product.type}
										/>
									))
								)}
							</div>
						</Tab.Panel>
					))}
				</Tab.Panels>
			</Tab.Group>
		</div>
	);
}
