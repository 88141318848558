import React from "react";
import { useParams } from "react-router-dom";
import designerContent from "../content/designerContent";

export default function Designer() {
	const params = useParams();

	const designer = designerContent.rooms.find(room => params.room === room.slug);

	const handleError = evt => console.log(evt);

	return (
		<iframe title="3D Design Tool" src={designer.url} className="w-full grow" onError={handleError} sandbox="allow-scripts allow-forms allow-same-origin" />
	)
}