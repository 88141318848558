import React, { useState } from "react";
import {
	ReactCompareSlider,
	ReactCompareSliderImage,
} from "react-compare-slider";
import dragIcon from "../assets/icons/drag.svg";
import tapIcon from "../assets/icons/tap.svg";

function ImageComparisonSlider({ before, after, style }) {
	const [isTooltipVisible, setIsTooltipVisible] = useState(true);

	const Handle = () => (
		<div className="relative h-full">
			<div className="absolute top-4 right-full -translate-x-1/2 bg-white p-2 shadow rounded-sm">
				Before
			</div>

			<div className="absolute left-1/2 -translate-x-1/2 h-full w-1 bg-white"></div>

			<div className="absolute top-4 translate-x-1/2 bg-white p-2 shadow rounded-sm">
				After
			</div>

			<div className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-full flex flex-col items-center">
				<div
					className={
						"text-white bg-gray-900 rounded-sm shadow p-2 whitespace-nowrap mb-3 animate-bounce transition-opacity " +
						(!isTooltipVisible && "opacity-0")
					}
				>
					Drag me!
				</div>

				<div
					className="w-12 h-12 p-3 rounded-full bg-white shadow"
					onTouchStart={() => setIsTooltipVisible(false)}
					onMouseDown={() => setIsTooltipVisible(false)}
				>
					<img className="w-full h-full object-contain" src={dragIcon} />
				</div>
			</div>
		</div>
	);

	return (
		<ReactCompareSlider
			style={style}
			itemOne={
				<ReactCompareSliderImage
					src={before}
					style={{ backgroundColor: "#FFF" }}
				/>
			}
			itemTwo={
				<ReactCompareSliderImage
					src={after}
					style={{ backgroundColor: "#FFF" }}
				/>
			}
			handle={<Handle />}
		/>
	);
}

function ImageComparisonTap({ before, after, style }) {
	const [isOpen, setIsOpen] = useState(true);

	const handleOnTap = () => {
		setIsOpen(!isOpen);
	};

	const openClasses = " clip-circle-full brightness-100";
	const closeClassed = " clip-circle-empty opacity-0 brightness-200";

	return (
		<button className="relative" style={style} onClick={handleOnTap}>
			<div
				className="absolute w-full h-full left-0 right-0 bottom-0 top-0 bg-cover bg-center"
				style={{ backgroundImage: `url('${before}')` }}
			/>
			<div
				className={
					"absolute w-full h-full left-0 right-0 bottom-0 top-0 bg-cover bg-center duration-700 z-10 transition-all" +
					(isOpen ? openClasses : closeClassed)
				}
				style={{ backgroundImage: `url('${after}')` }}
			/>

			<div className="absolute top-4 left-4 z-20 flex items-center bg-white p-4 shadow rounded-sm text-xl font-medium">
				<img className="w-7 h-7 mr-4" src={tapIcon} />
				{isOpen ? "Tap to see the Before!" : "Tap to see the After!"}
			</div>
		</button>
	);
}

export default function ImageComparison({
	before,
	after,
	style,
	mode = "slider",
}) {
	switch (mode) {
		case "tap":
			return ImageComparisonTap({ before, after, style });

		case "slider":
		default:
			return ImageComparisonSlider({ before, after, style });
	}
}
