import React, { useState } from "react";
import galleryContent from "../content/galleryContent";
import { Tab } from "@headlessui/react";
import ImageComparison from "../components/ImageComparison";

export default function Gallery() {
	const [selectedIndex, setSelectedIndex] = useState(0);
	const images = galleryContent.images;

	return (
		<div className="h-[calc(100vh-141px)] overflow-hidden">
			<Tab.Group selectedIndex={selectedIndex} onChange={setSelectedIndex}>
				<Tab.Panels>
					{images.map((image, i) => (
						<Tab.Panel key={i}>
							<ImageComparison
								style={{
									height: "calc(100vh - 4rem)",
									width: "100%",
								}}
								before={image.before}
								after={image.after}
								mode={image.mode}
							/>
						</Tab.Panel>
					))}
				</Tab.Panels>

				<Tab.List className="fixed bottom-0 left-0 bg-white flex gap-2 p-2 z-40">
					{images.map((image, i) => (
						<Tab
							className="ui-selected:brightness-[25%] transition focus:outline-none"
							key={i}
						>
							<img
								className="object-cover object-center w-20 h-20 bg-gray-200"
								src={image.after}
							/>
						</Tab>
					))}
				</Tab.List>
			</Tab.Group>
		</div>
	);
}
