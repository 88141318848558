import { useGet } from "./useWPAPI";

export function useGetDoors() {
	return useGet('/wp/v2/replacement_door', { per_page: 99, page: 1, has_thumbnail: true, orderby: 'title', order: 'asc' });
}

export function useGetWorktops() {
	return useGet('/wp/v2/worktop', { per_page: 99, page: 1, has_thumbnail: true, orderby: 'menu_order', order: 'asc' });
}

/**
 * This function gets all of the worktops & doors rather than an individual product. This would be very ineffecient *except* this uses a previously cached request.
 */
export function useGetProduct(type, id) {
	const products = {
		replacement_door: useGetDoors(),
		worktop: useGetWorktops()
	};

	const hasProducts = (resp) => resp.isOk && !resp.isLoading && resp.body && resp.body.length;

	const product = hasProducts(products[type]) ? products[type].body.find(product => product.id == id) : undefined;

	return {
		product,
		isLoading: products[type].isLoading,
	};
}