export default function Skeleton({
	children,
	className,
	style,
	placeholder = '',
	as = 'span',
	isLoading = false
}) {
	const As = as;

	if (isLoading) {
		children = placeholder;
		className += ' animate-pulse bg-gray-200 rounded text-transparent';
	}

	return (
		<As className={className} style={style}>
			{children}
		</As>
	)
}