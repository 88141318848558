import React from "react";
import designerContent from "../content/designerContent";
import CardsContainer from "../components/CardsContainer";
import Card from "../components/Card";

export default function Designer() {
	return (
		<div className="py-16 px-28">
			<h2 className="text-6xl mb-6">
				Choose your Kitchen Layout
			</h2>

			<p className="text-lg max-w-2xl leading-relaxed mb-14">
				Our 3D kitchen design tool could not be easier to use. Choose from a huge range of colours and styles for every part of your kitchen.
			</p>

			<CardsContainer>
				{
					designerContent.rooms.map((room, i) =>
						<Card key={i} to={`/designer/${room.slug}`} image={room.thumbnail}>
							{room.title}
						</Card>
					)
				}
			</CardsContainer>
		</div>
	)
}