import React from "react";
import { useNavigate } from "react-router-dom";

export default function TransitionLink({
	to,
	children,
	back = false,
	forward = false,
	className = '',
	disabled = false,
	scroll = null,
}) {
	const navigate = useNavigate();

	const handleNavigate = () => {
		if (back) {
			navigate(-1);
		}
		else if (forward) {
			navigate(1);
		}
		else {
			navigate(to);
		}

		if (scroll) {
			window.scrollTo({
				top: scroll,
				behavior: 'smooth'
			})
		}
	}

	const handleClick = (evt) => {
		evt.preventDefault();

		if (disabled) {
			return false;
		}

		if (!document.startViewTransition) {
			return handleNavigate();
		}

		document.startViewTransition(() => {
			handleNavigate();
		})
	}

	return (
		<button
			className={"cursor-pointer " + className}
			onClick={handleClick}
		>
			{children}
		</button>
	);
}